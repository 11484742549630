<template>
  <section class="page-commercial">
    <div class="banner">
      <p class="hd">JWP COMMERCIAL</p>
      <p class="title">
        <span>Nursing Home</span>
      </p>
      <p class="desc">
        <span
          >Promoting diversified elderly care services To alleviate the shortage
          of beds, obsolete facilities and slow technological update in nursing
          homes</span
        >
      </p>
    </div>
    <div class="standard">
      <p class="title">
        <span class="van-hairline--bottom">Service Standard</span>
      </p>
      <ul class="boxs">
        <li class="box">
          <img
            src="//static.hoolihome.com/global-jwp/assets/m/landmark/commercial/icon-1.png"
          />
          <span>Medical <br />resources</span>
        </li>
        <li class="box">
          <img
            src="//static.hoolihome.com/global-jwp/assets/m/landmark/commercial/icon-2.png"
          />
          <span>Advanced <br />equipment</span>
        </li>
        <li class="box">
          <img
            src="//static.hoolihome.com/global-jwp/assets/m/landmark/commercial/icon-3.png"
          />
          <span>Age appropriate <br />space</span>
        </li>
        <li class="box">
          <img
            src="//static.hoolihome.com/global-jwp/assets/m/landmark/commercial/icon-4.png"
          />
          <span>Custom <br />catering</span>
        </li>
        <li class="box">
          <img
            src="//static.hoolihome.com/global-jwp/assets/m/landmark/commercial/icon-5.png"
          />
          <span>Natural <br />resources</span>
        </li>
        <li class="box">
          <img
            src="//static.hoolihome.com/global-jwp/assets/m/landmark/commercial/icon-6.png"
          />
          <span>Health care <br />system</span>
        </li>
        <li class="box">
          <img
            src="//static.hoolihome.com/global-jwp/assets/m/landmark/commercial/icon-7.png"
          />
          <span>Rehabilitation <br />system</span>
        </li>
        <li class="box">
          <img
            src="//static.hoolihome.com/global-jwp/assets/m/landmark/commercial/icon-8.png"
          />
          <span>Physical <br />examination system</span>
        </li>
      </ul>
    </div>
    <div class="project kilcoole">
      <p class="title">
        <span class="van-hairline--bottom">Kilcoole Lodge Nursing Home</span>
      </p>
      <div class="content" :class="{ fold: isFold }">
        <p>
          Kilcoole Lodge Nursing Home dedicated to solving severe social elderly
          care in Ireland, located in Southern Dublin's affluent area, in strict
          compliance with Ireland Health Service Executive (HSE), advanced
          facilities and equipment, and conducts design, construction, and
          operation by referring to the current cutting-edge nursing home design
          scheme and caring configuration. In Ireland, 47% of the elderly over
          65 had dementia; unfortunately, 80% of nursing homes are unable to
          provide dementia care. Currently, Kilcoole Lodge Nursing Home is one
          of the region's exclusive nursing homes that provide specialist care
          for Alzheimer's disease.
        </p>
      </div>
      <div class="look-more">
        <div class="opacity" v-if="isFold">
          <img src="//static.hoolihome.com/global-jwp/assets/m/text-mask.png" />
        </div>
        <div class="btn" @click="handleLookMore">
          <span>{{ btnText }}</span>
          <img
            :src="
              isFold
                ? '//static.hoolihome.com/global-jwp/assets/m/arrow-more-down.png'
                : '//static.hoolihome.com/global-jwp/assets/m/arrow-more-up.png'
            "
          />
        </div>
      </div>
      <div class="scroll-wrap">
        <ul class="boxs">
          <li class="box">
            <van-image
              width="223"
              height="125"
              fit="cover"
              lazy-load
              src="//static.hoolihome.com/global-jwp/assets/m/landmark/commercial/kilcoole-1.jpg"
            />
          </li>
          <li class="box">
            <van-image
              width="223"
              height="125"
              fit="cover"
              lazy-load
              src="//static.hoolihome.com/global-jwp/assets/m/landmark/commercial/kilcoole-2.jpg"
            />
          </li>
          <li class="box">
            <van-image
              width="223"
              height="125"
              fit="cover"
              lazy-load
              src="//static.hoolihome.com/global-jwp/assets/m/landmark/commercial/kilcoole-3.jpg"
            />
          </li>
          <li class="box">
            <van-image
              width="223"
              height="125"
              fit="cover"
              lazy-load
              src="//static.hoolihome.com/global-jwp/assets/m/landmark/commercial/kilcoole-4.jpg"
            />
          </li>
          <li class="box">
            <van-image
              width="223"
              height="125"
              fit="cover"
              lazy-load
              src="//static.hoolihome.com/global-jwp/assets/m/landmark/commercial/kilcoole-5.jpg"
            />
          </li>
          <li class="box">
            <van-image
              width="223"
              height="125"
              fit="cover"
              lazy-load
              src="//static.hoolihome.com/global-jwp/assets/m/landmark/commercial/kilcoole-6.jpg"
            />
          </li>
        </ul>
      </div>
    </div>
    <div class="project dundalk">
      <p class="title">
        <span class="van-hairline--bottom">Dundalk Nursing Home</span>
      </p>
      <div class="content" :class="{ fold: isFold2 }">
        <p>
          Dundalk Nursing Home empowered from fully equipped resources,
          excellent location, pleasant natural climate, harmonious cultural
          atmosphere, and quiet and beautiful environment, covering 12,000
          square meters, 127 beds with professional medical equipment, and
          health care team. The project, which has been approved by the
          government, raised 19 million euros from 19 investors.
        </p>
      </div>
      <div class="look-more">
        <div class="opacity" v-if="isFold2">
          <img src="//static.hoolihome.com/global-jwp/assets/m/text-mask.png" />
        </div>
        <div class="btn" @click="handleLookMore2">
          <span>{{ btnText2 }}</span>
          <img
            :src="
              isFold2
                ? '//static.hoolihome.com/global-jwp/assets/m/arrow-more-down.png'
                : '//static.hoolihome.com/global-jwp/assets/m/arrow-more-up.png'
            "
          />
        </div>
      </div>
      <div class="scroll-wrap">
        <ul class="boxs">
          <li class="box">
            <van-image
              width="223"
              height="125"
              fit="cover"
              lazy-load
              src="//static.hoolihome.com/global-jwp/assets/m/landmark/commercial/dundalk-1.jpg"
            />
          </li>
          <li class="box">
            <van-image
              width="223"
              height="125"
              fit="cover"
              lazy-load
              src="//static.hoolihome.com/global-jwp/assets/m/landmark/commercial/dundalk-2.jpg"
            />
          </li>
          <li class="box">
            <van-image
              width="223"
              height="125"
              fit="cover"
              lazy-load
              src="//static.hoolihome.com/global-jwp/assets/m/landmark/commercial/dundalk-3.jpg"
            />
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      isFold: true,
      btnText: "View more",
      isFold2: true,
      btnText2: "View more"
    };
  },
  beforeCreate() {
    document.title = "Nursing Home";
  },
  methods: {
    handleLookMore() {
      if (this.isFold) {
        this.isFold = false;
        this.btnText = "Collapse";
      } else {
        this.isFold = true;
        this.btnText = "View more";
      }
    },
    handleLookMore2() {
      if (this.isFold2) {
        this.isFold2 = false;
        this.btnText2 = "Collapse";
      } else {
        this.isFold2 = true;
        this.btnText2 = "View more";
      }
    }
  }
};
</script>

<style lang="less" scoped>
.page-commercial {
  .banner {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: 360px;
    background: url("//static.hoolihome.com/global-jwp/assets/m/landmark/commercial/banner.jpg")
      no-repeat center center / cover;
    .hd {
      padding-bottom: 83px;
      font-size: 22px;
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
      line-height: 30px;
      text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
    }
    .title {
      padding-bottom: 16px;
      span {
        padding-bottom: 4px;
        font-size: 20px;
        font-weight: bold;
        color: rgba(255, 255, 255, 1);
        border-bottom: 2px rgba(255, 255, 255, 0.5) solid;
      }
    }
    .desc {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 22px 39px;
      font-size: 12px;
      font-weight: 400;
      color: rgba(255, 255, 255, 1);
      text-align: center;
      span {
        display: inline-block;
        line-height: 17px;
      }
    }
  }
  .standard {
    padding: 24px 22px 20px;
    text-align: center;
    .title {
      padding-bottom: 32px;
      text-align: center;
      span {
        padding-bottom: 4px;
        font-size: 20px;
        font-weight: 300;
        color: rgba(1, 61, 125, 1);
        &::after {
          border-color: rgba(1, 61, 125, 1);
        }
      }
    }
    .boxs {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .box {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 20px;
        width: 68px;
        img {
          width: 26px;
        }
        span {
          margin-top: 4px;
          font-size: 12px;
          font-weight: 400;
          color: rgba(38, 38, 38, 1);
          line-height: 17px;
        }
      }
    }
  }
  .project {
    padding-bottom: 40px;
    text-align: center;
    &.dundalk {
      padding-bottom: 50px;
    }
    .title {
      padding-bottom: 27px;
      text-align: center;
      span {
        padding-bottom: 4px;
        font-size: 20px;
        font-weight: 300;
        color: rgba(1, 61, 125, 1);
        &::after {
          border-color: rgba(1, 61, 125, 1);
        }
      }
    }
    .content {
      padding: 0 22px 16px;
      transition: all 0.3s;
      &.fold {
        height: 56px;
        overflow: hidden;
      }
      p {
        text-align: left;
        font-size: 14px;
        color: rgba(38, 38, 38, 1);
        line-height: 20px;
      }
    }
    .look-more {
      padding: 0 22px 20px;
      .opacity {
        height: 20px;
        margin: -20px 0 16px;
        img {
          width: 100%;
        }
      }
      .btn {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 34px;
        border-radius: 4px;
        border: 1px solid rgba(38, 38, 38, 1);
        &:active {
          opacity: 0.8;
        }
        span {
          font-size: 12px;
          font-weight: 400;
          color: rgba(38, 38, 38, 1);
        }
        img {
          width: 8px;
          margin-left: 8px;
        }
      }
    }
    .scroll-wrap {
      width: 100vw;
      overflow-x: scroll;
      .boxs {
        display: flex;
        padding-left: 22px;
        .box {
          flex-shrink: 0;
          padding-right: 14px;
          overflow-y: hidden;
          &:last-child {
            padding-right: 22px;
          }
          .van-image {
            overflow: hidden;
            border-radius: 4px;
          }
        }
      }
    }
  }
}
</style>
